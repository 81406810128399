import React, {useState} from 'react';
import {LoaderComponent} from 'ht-styleguide';
import cn from 'classnames';

import styles from '@components/Kustomer/kustomer.module.scss';

/**
 * Trying to get around the Kustomer Chat - it's very detrimental to the performance of the site.
 * So, we are going to lazy load it with 8 seconds delay, BUT offer the visual cue to the user that they can
 * click it and initiate the load sooner.
 *
 * @param {BaseAnyFunction} loadKustomerScript
 * @returns {React.JSX.Element}
 * @constructor
 */
const KustomerPlaceholder = ({loadKustomerScript}: {loadKustomerScript: BaseAnyFunction}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classPlaceholder = cn(styles.kustomerPlaceholder, {
    [styles.isLoading]: isLoading,
  });

  return (
    <div
      onClick={() => {
        setIsLoading(true);
        loadKustomerScript({setOpen: true});
      }}
      tabIndex={0}
      role="button"
      className={classPlaceholder}
    >
      <img
        src="https://cdn.kustomerhostedcontent.com/media/5c0f0d11a9206b001981d5b1/bfc100ddbcc73a39e82320e2e30125b4.svg"
        className={styles.image}
        alt="hellotech customer chat"
      />
      {isLoading && (
        <div className="positionAbsolute">
          <LoaderComponent />
        </div>
      )}
    </div>
  );
};

export default KustomerPlaceholder;
