import React, {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';

/* Stores */
import {useResponsiveDeviceType} from '@store/client/store.responsive';

/* Store Contexts */
import useUserCache from '@store/queries/user/useUser.cache';
import useCartCache from '@store/queries/cart/useCart.cache';

/* Class */
import HTKustomerChat from '@components/Kustomer/Kustomer.methods';

/* Components */
import KustomerPlaceholder from '@components/Kustomer/Kustomer.Placeholder';

/* Utils */
import {noop} from '@utils/event';
import {browserInfo, isBrowser} from '@utils/ui';
import {sleep} from '@utils/helpers';

import {BaseLayoutProps} from '@components/PageLayouts/BaseLayoutContent/Layout.Base';
import {SCRIPT_LOAD_TIME_KUSTOMER} from '@integrations/integrations.constants';

/*
 * We could just copy from client, but the issue here is we need to initialize the download after user
 * interaction. So, we need listeners to facilitate this.
 *
 * params: hideChat - this boolean is passed on the static generated page in the getLayout
 * */
type KustomerChatProps = {
  hideChat?: boolean;
  pageConfig?: BaseLayoutProps['pageConfig'];
};

declare const window: any;

/**
 * 1. Load Kustomer after long delay
 *    - If user interacts with icon, load immediately
 *
 *    Note: Kustomer is too detrimental to load times, so we need to delay it until
 *          after Segment & accessibe. It's lame, I agree.
 * @param {KustomerChatProps} props
 * @returns {React.JSX.Element | null}
 * @constructor
 */
const KustomerComponent = (props: KustomerChatProps) => {
  /* Local State */
  const [showPlaceholder, setShowPlaceholder] = React.useState<boolean>(true);

  /* Destructure props */
  const {hideChat = false} = props;

  /* Hooks */
  const {query = {}, asPath} = useRouter();
  const enqueueRef = useRef<BaseAnyFunction[]>([]);
  const user = useUserCache();
  const cart = useCartCache();
  const browser = browserInfo();
  const refScriptIsLoaded = useRef(false);

  /* Client State */
  const deviceType = useResponsiveDeviceType();

  /* Const */
  const isProductPage = Boolean(query.productSeoName);

  /* Methods */
  const loadEnqueue = () => {
    /* If we qued any methods fire them */
    enqueueRef.current.forEach(m => m());
  };

  const loadUser = () => {
    if (user?.kustomerJwt) {
      window?.Kustomer?.login({
        jwtToken: user.kustomerJwt,
      });
    }
  };
  /*
   * Script is loaded so lets initialize the start/and pertinent info
   * */
  const initializeStart = () => {
    HTKustomerChat.start({
      hideChatIconOverride: false,
      callBack: () => {
        loadUser();
        loadEnqueue();

        /* Initial listener. Subsequent starts shouldn't need this */
        window.Kustomer.addListener('onConversationCreate', (res: {conversationId: string}) => {
          window.Kustomer.describeConversation({
            conversationId: res.conversationId,
            customAttributes: {
              chatDeviceTypeStr: deviceType,
              chatBrowserTypeStr: browser.name,
              chatOriginUrlStr: window.location.href,
              /* we might not need this here. lets see how product page fleshes out */
              ...(isProductPage ? {chatTeamIntentStr: 'Product Questions'} : {}),
            },
          });
        });
      },
    });
  };

  /* Lets see if Kustomer is already in the Window */
  const isKustomerInWindow = () => {
    if (!isBrowser()) return false;

    if ('Kustomer' in window) {
      return true;
    }

    return false;
  };

  const loadKustomerScript = ({setOpen}: {setOpen: boolean} = {setOpen: false}) => {
    if (setOpen && isKustomerInWindow() && refScriptIsLoaded.current) {
      window.Kustomer.open();
    };

    if (!isKustomerInWindow() && !refScriptIsLoaded.current) {
      /* There might be a slight delay in when Kustomer is added to the window. Lets add our own check */
      refScriptIsLoaded.current = true;
      const script = document.createElement('script');
      script.defer = true;
      script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
      script.setAttribute('data-kustomer-api-key', `${process.env.NEXT_PUBLIC_KUSTOMER_API_KEY}`);
      script.onload = () => {
        if (setOpen) {
          // Poll until the script is fully executed
          const checkLoaded = setInterval(() => {
            /* Notice the delays? Functions are on the object but "not ready": why? */
            if ('Kustomer' in window && 'open' in window.Kustomer && 'isChatAvailable' in window.Kustomer) {
              const {availability} = window.Kustomer?.isChatAvailable?.() ?? {availability: 'offline'};
              if (availability === 'online') {
                setTimeout(() => {
                  clearInterval(checkLoaded);
                  setShowPlaceholder(false);
                  window.Kustomer.open();
                }, 1000);
              }
            }
          }, 100);
        }
      };

      window.document.body.appendChild(script);
    }
  };
  /*
   * Lets load the kustomer chat script
   * */
  const loadKustomer =
    (predicate: () => boolean = () => true) =>
    () => {
      const fireKustomer = () => {
        if (predicate()) {
          loadKustomerScript();
        }
      };

      window.addEventListener('load', () => {
        setTimeout(() => {
          fireKustomer();
        }, query?.chat ? 1500 : SCRIPT_LOAD_TIME_KUSTOMER); // Load kustomer
      });
    };

  const addAndRemoveListeners = (type: string) => {
    window[`${type}EventListener`]('kustomerLoaded', initializeStart);
    window[`${type}EventListener`](
      'loadAndOpenChat',
      () => loadKustomerScript({setOpen: true}),
      {once: true},
    );
  };

  /*
   * Set up and remove listeners
   * */
  useEffect(() => {
    if (!hideChat) {
      addAndRemoveListeners('add');

      /*
        Since we we want to wait till the session is loaded, we'll just do this here.
        We know the user wants to explicitly have the chat opened, so we load it, then open it.
      */
      if (query.chat) {
        /* this prevents closure issues */
        enqueueRef.current = [...enqueueRef.current, () => window.Kustomer.open()];
        window.dispatchEvent(new CustomEvent('loadAndOpenChat'));
      }

      /* IF we fall within a mobile overrride */
      if (!query.chat) {
        sleep(0).then(() => {
          loadKustomer()();
        });
      }

      /* Remove event listeners */
      return () => addAndRemoveListeners('remove');
    }

    return noop;
  }, [cart, hideChat, asPath]);

  useEffect(() => {
    if (hideChat) {
      // close chat
    }
  }, [hideChat]);

  /* This Chat needs a redo. Stopgaps */
  if (!showPlaceholder || isKustomerInWindow()) return null;

  return <KustomerPlaceholder loadKustomerScript={loadKustomerScript} />;
};

export default KustomerComponent;
